.sidebar .nav-link.active {
    color: #fff;
}

.sidebar .nav-link {
    color: #b7bcc0;
}

.sidebar .nav-link.menu-card {
    background-color: #727477;
}

.sidebar li {
    border-bottom: 1px solid rgba(240, 241, 242, .06);
}


#sidebarMenu {
    background-color: #494e54;
}

#sidebarMenu a:hover {
    color: #fff;
    text-decoration: none;
}

#sidebarMenu header {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    padding: .5rem 1rem;
    margin-bottom: 0px;
    background-color: #343a40b5;
    line-height: 21px;

}

#sidebarMenu header .group {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#sidebarMenu header .group .icon {
    /*padding: 0 5px 2px 5px;*/
}

.sidebar-sticky {
    padding-top: 0px !important;
}

