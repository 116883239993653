#root main {
    padding-left: 0;
    padding-right: 0;
}

main header.menu {
    padding: 8px 16px 8px 16px;
    background-color: #70b0e0;
    font-weight: bold;
    color: #000;
}

/*main header.ruler {*/
/*    padding: 2px 16px 2px 16px;*/
/*    background-color: #fff;*/
/*    color: #000;*/
/*}*/

main article {
    /*padding: 8px 16px 8px 16px;*/
    padding: 0;
    overflow: auto;
    white-space: nowrap;
}



.selected-cell {
    /*background-color: #ccc !important;*/
    /*border: 1px solid #ccc !important;*/
    -webkit-animation: blinkingBackground .5s infinite; /* Safari 4+ */
    -moz-animation: blinkingBackground .5s infinite; /* Fx 5+ */
    -o-animation: blinkingBackground .5s infinite; /* Opera 12+ */
    animation: blinkingBackground .5s infinite; /* IE 10+, Fx 29+ */
}

@keyframes blinkingBackground {
    0% {
        background-color: #ccc;
        border: 1px solid #ccc;
        /*border-top: 1px solid #ccc !important;*/
        /*border-bottom: 1px solid #ccc !important;*/
    }
    25% {
        background-color: #ccc;
        /*border-left: 1px solid #ccc !important;*/
        border: 1px solid #ccc;
    }
    50% {
        background-color: #ccc;
        /*border-left: 1px solid #ccc !important;*/
        border: 1px solid #ccc;
    }
    75% {
        background-color: #fff;
        /*border-left: 1px solid #fff !important;*/
        border: 1px solid #fff;
    }
    100% {
        background-color: #fff;
        /*border-left: 1px solid #fff !important;*/
        border: 1px solid #fff;
    }
}

main article .row-line .cell {
    border: 1px solid #fff;
}

main article .row-ruler .cell {
    text-orientation: upright;
    writing-mode: tb-rl;
    text-align: center;
    padding: 0;
    background-color: #ddf1df;
    border-bottom: 1px solid #cde0cf;
    border-left: 1px solid #cde0cf;
    width: 15px;
    display: inline-block;
    white-space: normal;
    line-height: 15px;
    height: 35px;
}

main article .row-ruler {
    height: 35px;
}

main article .row-line .cell {
    text-align: center;
    padding: 0;
    width: 15px;
    display: inline-block;
    white-space: normal;
    /*border-left: 1px solid #fff;*/
}

main article .row-line {
    height: 23px;
}

main article .row-line .highlight {
    background-color: #f4f0b6;
    border-bottom: 1px solid #dbd698;
    border-top: 1px solid #dbd698;
    border-left: 1px solid #f4f0b6;
    border-right: 1px solid #f4f0b6;
}

main article .row-line .highlight-start {
    background-color: #f4f0b6;
    border-bottom: 1px solid #dbd698;
    border-top: 1px solid #dbd698;
    border-left: 1px solid #dbd698;
    border-right: 1px solid #f4f0b6;
}

main article .row-line .highlight-end {
    background-color: #f4f0b6;
    border-bottom: 1px solid #dbd698;
    border-top: 1px solid #dbd698;
    border-right: 1px solid #dbd698;
    border-left: 1px solid #f4f0b6;
}

.menu-item-icon {
    position: absolute;
    right: 0;
    padding-right: 10px;
}